.icon-yellow {
  background-color: #F3CF31 !important;
}

.icon-white {
  background-color: #fff !important;
}

.icon-black {
  background-color: #000 !important;
}

.icon-red {
  background-color: red !important;
}

.icon-green {
  background-color: #31f39f !important;
}

.activity-icon {
  mask: url(/assets/icons/anglo-icon-activity-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-activity-outline.svg) no-repeat center !important;
}
.alert-circle-icon {
  mask: url(/assets/icons/anglo-icon-alert-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-alert-circle-outline.svg) no-repeat center !important;
}
.alert-triangle-icon {
  mask: url(/assets/icons/anglo-icon-alert-triangle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-alert-triangle-outline.svg) no-repeat center !important;
}
.archive-icon {
  mask: url(/assets/icons/anglo-icon-archive-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-archive-outline.svg) no-repeat center !important;
}
.arrow-back-icon {
  mask: url(/assets/icons/anglo-icon-arrow-back-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-back-outline.svg) no-repeat center !important;
}
.arrow-circle-down-icon {
  mask: url(/assets/icons/anglo-icon-arrow-circle-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-circle-down-outline.svg) no-repeat center !important;
}
.arrow-circle-left-icon {
  mask: url(/assets/icons/anglo-icon-arrow-circle-left-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-circle-left-outline.svg) no-repeat center !important;
}
.arrow-circle-right-icon {
  mask: url(/assets/icons/anglo-icon-arrow-circle-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-circle-right-outline.svg) no-repeat center !important;
}
.arrow-circle-up-icon {
  mask: url(/assets/icons/anglo-icon-arrow-circle-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-circle-up-outline.svg) no-repeat center !important;
}
.arrow-down-icon {
  mask: url(/assets/icons/anglo-icon-arrow-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-down-outline.svg) no-repeat center !important;
}
.arrow-downward-icon {
  mask: url(/assets/icons/anglo-icon-arrow-downward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-downward-outline.svg) no-repeat center !important;
}
.arrow-forward-icon {
  mask: url(/assets/icons/anglo-icon-arrow-forward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-forward-outline.svg) no-repeat center !important;
}
.arrowhead-down-icon {
  mask: url(/assets/icons/anglo-icon-arrowhead-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrowhead-down-outline.svg) no-repeat center !important;
}
.arrowhead-left-icon {
  mask: url(/assets/icons/anglo-icon-arrowhead-left-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrowhead-left-outline.svg) no-repeat center !important;
}
.arrowhead-right-icon {
  mask: url(/assets/icons/anglo-icon-arrowhead-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrowhead-right-outline.svg) no-repeat center !important;
}
.arrowhead-up-icon {
  mask: url(/assets/icons/anglo-icon-arrowhead-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrowhead-up-outline.svg) no-repeat center !important;
}
.arrow-ios-back-icon {
  mask: url(/assets/icons/anglo-icon-arrow-ios-back-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-ios-back-outline.svg) no-repeat center !important;
}
.arrow-ios-downward-icon {
  mask: url(/assets/icons/anglo-icon-arrow-ios-downward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-ios-downward-outline.svg) no-repeat center !important;
}
.arrow-ios-forward-icon {
  mask: url(/assets/icons/anglo-icon-arrow-ios-forward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-ios-forward-outline.svg) no-repeat center !important;
}
.arrow-ios-upward-icon {
  mask: url(/assets/icons/anglo-icon-arrow-ios-upward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-ios-upward-outline.svg) no-repeat center !important;
}
.arrow-up-icon {
  mask: url(/assets/icons/anglo-icon-arrow-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-up-outline.svg) no-repeat center !important;
}
.arrow-upward-icon {
  mask: url(/assets/icons/anglo-icon-arrow-upward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-arrow-upward-outline.svg) no-repeat center !important;
}
.at-icon {
  mask: url(/assets/icons/anglo-icon-at-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-at-outline.svg) no-repeat center !important;
}
.attach-2-icon {
  mask: url(/assets/icons/anglo-icon-attach-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-attach-2-outline.svg) no-repeat center !important;
}
.attach-icon {
  mask: url(/assets/icons/anglo-icon-attach-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-attach-outline.svg) no-repeat center !important;
}
.award-icon {
  mask: url(/assets/icons/anglo-icon-award-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-award-outline.svg) no-repeat center !important;
}
.back-icon {
  mask: url(/assets/icons/anglo-icon-back.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-back.svg) no-repeat center !important;
}
.backspace-icon {
  mask: url(/assets/icons/anglo-icon-backspace-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-backspace-outline.svg) no-repeat center !important;
}
.bar-chart-2-icon {
  mask: url(/assets/icons/anglo-icon-bar-chart-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bar-chart-2-outline.svg) no-repeat center !important;
}
.bar-chart-icon {
  mask: url(/assets/icons/anglo-icon-bar-chart-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bar-chart-outline.svg) no-repeat center !important;
}
.battery-icon {
  mask: url(/assets/icons/anglo-icon-battery-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-battery-outline.svg) no-repeat center !important;
}
.behance-icon {
  mask: url(/assets/icons/anglo-icon-behance-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-behance-outline.svg) no-repeat center !important;
}
.bell-off-icon {
  mask: url(/assets/icons/anglo-icon-bell-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bell-off-outline.svg) no-repeat center !important;
}
.bell-icon {
  mask: url(/assets/icons/anglo-icon-bell-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bell-outline.svg) no-repeat center !important;
}
.bluetooth-icon {
  mask: url(/assets/icons/anglo-icon-bluetooth-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bluetooth-outline.svg) no-repeat center !important;
}
.bookmark-icon {
  mask: url(/assets/icons/anglo-icon-bookmark-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bookmark-outline.svg) no-repeat center !important;
}
.book-open-icon {
  mask: url(/assets/icons/anglo-icon-book-open-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-book-open-outline.svg) no-repeat center !important;
}
.book-icon {
  mask: url(/assets/icons/anglo-icon-book-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-book-outline.svg) no-repeat center !important;
}
.briefcase-icon {
  mask: url(/assets/icons/anglo-icon-briefcase-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-briefcase-outline.svg) no-repeat center !important;
}
.browser-icon {
  mask: url(/assets/icons/anglo-icon-browser-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-browser-outline.svg) no-repeat center !important;
}
.brush-icon {
  mask: url(/assets/icons/anglo-icon-brush-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-brush-outline.svg) no-repeat center !important;
}
.bulb-icon {
  mask: url(/assets/icons/anglo-icon-bulb-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-bulb-outline.svg) no-repeat center !important;
}
.calendar-icon {
  mask: url(/assets/icons/anglo-icon-calendar-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-calendar-outline.svg) no-repeat center !important;
}
.camera-icon {
  mask: url(/assets/icons/anglo-icon-camera-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-camera-outline.svg) no-repeat center !important;
}
.car-icon {
  mask: url(/assets/icons/anglo-icon-car-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-car-outline.svg) no-repeat center !important;
}
.cast-icon {
  mask: url(/assets/icons/anglo-icon-cast-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-cast-outline.svg) no-repeat center !important;
}
.charging-icon {
  mask: url(/assets/icons/anglo-icon-charging-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-charging-outline.svg) no-repeat center !important;
}
.checkmark-circle-2-icon {
  mask: url(/assets/icons/anglo-icon-checkmark-circle-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-checkmark-circle-2-outline.svg) no-repeat center !important;
}
.checkmark-circle-icon {
  mask: url(/assets/icons/anglo-icon-checkmark-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-checkmark-circle-outline.svg) no-repeat center !important;
}
.checkmark-icon {
  mask: url(/assets/icons/anglo-icon-checkmark-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-checkmark-outline.svg) no-repeat center !important;
}
.checkmark-square-2-icon {
  mask: url(/assets/icons/anglo-icon-checkmark-square-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-checkmark-square-2-outline.svg) no-repeat center !important;
}
.checkmark-square-icon {
  mask: url(/assets/icons/anglo-icon-checkmark-square-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-checkmark-square-outline.svg) no-repeat center !important;
}
.chevron-down-icon {
  mask: url(/assets/icons/anglo-icon-chevron-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-chevron-down-outline.svg) no-repeat center !important;
}
.chevron-left-icon {
  mask: url(/assets/icons/anglo-icon-chevron-left-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-chevron-left-outline.svg) no-repeat center !important;
}
.chevron-right-icon {
  mask: url(/assets/icons/anglo-icon-chevron-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-chevron-right-outline.svg) no-repeat center !important;
}
.chevron-up-icon {
  mask: url(/assets/icons/anglo-icon-chevron-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-chevron-up-outline.svg) no-repeat center !important;
}
.clipboard-icon {
  mask: url(/assets/icons/anglo-icon-clipboard-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-clipboard-outline.svg) no-repeat center !important;
}
.clock-icon {
  mask: url(/assets/icons/anglo-icon-clock-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-clock-outline.svg) no-repeat center !important;
}
.close-circle-icon {
  mask: url(/assets/icons/anglo-icon-close-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-close-circle-outline.svg) no-repeat center !important;
}
.close-icon {
  mask: url(/assets/icons/anglo-icon-close-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-close-outline.svg) no-repeat center !important;
}
.close-square-icon {
  mask: url(/assets/icons/anglo-icon-close-square-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-close-square-outline.svg) no-repeat center !important;
}
.cloud-download-icon {
  mask: url(/assets/icons/anglo-icon-cloud-download-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-cloud-download-outline.svg) no-repeat center !important;
}
.cloud-upload-icon {
  mask: url(/assets/icons/anglo-icon-cloud-upload-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-cloud-upload-outline.svg) no-repeat center !important;
}
.code-download-icon {
  mask: url(/assets/icons/anglo-icon-code-download-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-code-download-outline.svg) no-repeat center !important;
}
.code-icon {
  mask: url(/assets/icons/anglo-icon-code-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-code-outline.svg) no-repeat center !important;
}
.collapse-icon {
  mask: url(/assets/icons/anglo-icon-collapse-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-collapse-outline.svg) no-repeat center !important;
}
.color-palette-icon {
  mask: url(/assets/icons/anglo-icon-color-palette-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-color-palette-outline.svg) no-repeat center !important;
}
.color-picker-icon {
  mask: url(/assets/icons/anglo-icon-color-picker-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-color-picker-outline.svg) no-repeat center !important;
}
.compass-icon {
  mask: url(/assets/icons/anglo-icon-compass-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-compass-outline.svg) no-repeat center !important;
}
.copy-icon {
  mask: url(/assets/icons/anglo-icon-copy-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-copy-outline.svg) no-repeat center !important;
}
.corner-down-left-icon {
  mask: url(/assets/icons/anglo-icon-corner-down-left-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-down-left-outline.svg) no-repeat center !important;
}
.corner-down-right-icon {
  mask: url(/assets/icons/anglo-icon-corner-down-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-down-right-outline.svg) no-repeat center !important;
}
.corner-left-down-icon {
  mask: url(/assets/icons/anglo-icon-corner-left-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-left-down-outline.svg) no-repeat center !important;
}
.corner-left-up-icon {
  mask: url(/assets/icons/anglo-icon-corner-left-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-left-up-outline.svg) no-repeat center !important;
}
.corner-right-down-icon {
  mask: url(/assets/icons/anglo-icon-corner-right-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-right-down-outline.svg) no-repeat center !important;
}
.corner-right-up-icon {
  mask: url(/assets/icons/anglo-icon-corner-right-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-right-up-outline.svg) no-repeat center !important;
}
.corner-up-left-icon {
  mask: url(/assets/icons/anglo-icon-corner-up-left-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-up-left-outline.svg) no-repeat center !important;
}
.corner-up-right-icon {
  mask: url(/assets/icons/anglo-icon-corner-up-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-corner-up-right-outline.svg) no-repeat center !important;
}
.credit-card-icon {
  mask: url(/assets/icons/anglo-icon-credit-card-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-credit-card-outline.svg) no-repeat center !important;
}
.crop-icon {
  mask: url(/assets/icons/anglo-icon-crop-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-crop-outline.svg) no-repeat center !important;
}
.cube-icon {
  mask: url(/assets/icons/anglo-icon-cube-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-cube-outline.svg) no-repeat center !important;
}
.dash-icon {
  mask: url(/assets/icons/anglo-icon-dash.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-dash.svg) no-repeat center !important;
}
.diagonal-arrow-left-down-icon {
  mask: url(/assets/icons/anglo-icon-diagonal-arrow-left-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-diagonal-arrow-left-down-outline.svg) no-repeat center !important;
}
.diagonal-arrow-left-up-icon {
  mask: url(/assets/icons/anglo-icon-diagonal-arrow-left-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-diagonal-arrow-left-up-outline.svg) no-repeat center !important;
}
.diagonal-arrow-right-down-icon {
  mask: url(/assets/icons/anglo-icon-diagonal-arrow-right-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-diagonal-arrow-right-down-outline.svg) no-repeat center !important;
}
.diagonal-arrow-right-up-icon {
  mask: url(/assets/icons/anglo-icon-diagonal-arrow-right-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-diagonal-arrow-right-up-outline.svg) no-repeat center !important;
}
.done-all-icon {
  mask: url(/assets/icons/anglo-icon-done-all-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-done-all-outline.svg) no-repeat center !important;
}
.download-icon {
  mask: url(/assets/icons/anglo-icon-download-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-download-outline.svg) no-repeat center !important;
}
.droplet-off-icon {
  mask: url(/assets/icons/anglo-icon-droplet-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-droplet-off-outline.svg) no-repeat center !important;
}
.droplet-icon {
  mask: url(/assets/icons/anglo-icon-droplet-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-droplet-outline.svg) no-repeat center !important;
}
.edit-2-icon {
  mask: url(/assets/icons/anglo-icon-edit-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-edit-2-outline.svg) no-repeat center !important;
}
.edit-icon {
  mask: url(/assets/icons/anglo-icon-edit-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-edit-outline.svg) no-repeat center !important;
}
.email-icon {
  mask: url(/assets/icons/anglo-icon-email-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-email-outline.svg) no-repeat center !important;
}
.excludeuser-icon {
  mask: url(/assets/icons/anglo-icon-excludeuser.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-excludeuser.svg) no-repeat center !important;
}
.expand-icon {
  mask: url(/assets/icons/anglo-icon-expand-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-expand-outline.svg) no-repeat center !important;
}
.external-link-icon {
  mask: url(/assets/icons/anglo-icon-external-link-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-external-link-outline.svg) no-repeat center !important;
}
.eye-off-2-icon {
  mask: url(/assets/icons/anglo-icon-eye-off-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-eye-off-2-outline.svg) no-repeat center !important;
}
.eye-off-icon {
  mask: url(/assets/icons/anglo-icon-eye-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-eye-off-outline.svg) no-repeat center !important;
}
.eye-icon {
  mask: url(/assets/icons/anglo-icon-eye-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-eye-outline.svg) no-repeat center !important;
}
.facebook-icon {
  mask: url(/assets/icons/anglo-icon-facebook-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-facebook-outline.svg) no-repeat center !important;
}
.file-add-icon {
  mask: url(/assets/icons/anglo-icon-file-add-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-file-add-outline.svg) no-repeat center !important;
}
.file-icon {
  mask: url(/assets/icons/anglo-icon-file-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-file-outline.svg) no-repeat center !important;
}
.file-remove-icon {
  mask: url(/assets/icons/anglo-icon-file-remove-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-file-remove-outline.svg) no-repeat center !important;
}
.file-text-icon {
  mask: url(/assets/icons/anglo-icon-file-text-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-file-text-outline.svg) no-repeat center !important;
}
.film-icon {
  mask: url(/assets/icons/anglo-icon-film-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-film-outline.svg) no-repeat center !important;
}
.flag-icon {
  mask: url(/assets/icons/anglo-icon-flag-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-flag-outline.svg) no-repeat center !important;
}
.flash-off-icon {
  mask: url(/assets/icons/anglo-icon-flash-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-flash-off-outline.svg) no-repeat center !important;
}
.flash-icon {
  mask: url(/assets/icons/anglo-icon-flash-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-flash-outline.svg) no-repeat center !important;
}
.flip-2-icon {
  mask: url(/assets/icons/anglo-icon-flip-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-flip-2-outline.svg) no-repeat center !important;
}
.flip-icon {
  mask: url(/assets/icons/anglo-icon-flip-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-flip-outline.svg) no-repeat center !important;
}
.folder-add-icon {
  mask: url(/assets/icons/anglo-icon-folder-add-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-folder-add-outline.svg) no-repeat center !important;
}
.folder-icon {
  mask: url(/assets/icons/anglo-icon-folder-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-folder-outline.svg) no-repeat center !important;
}
.folder-remove-icon {
  mask: url(/assets/icons/anglo-icon-folder-remove-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-folder-remove-outline.svg) no-repeat center !important;
}
.funnel-icon {
  mask: url(/assets/icons/anglo-icon-funnel-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-funnel-outline.svg) no-repeat center !important;
}
.gift-icon {
  mask: url(/assets/icons/anglo-icon-gift-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-gift-outline.svg) no-repeat center !important;
}
.github-icon {
  mask: url(/assets/icons/anglo-icon-github-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-github-outline.svg) no-repeat center !important;
}
.globe-2-icon {
  mask: url(/assets/icons/anglo-icon-globe-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-globe-2-outline.svg) no-repeat center !important;
}
.globe-icon {
  mask: url(/assets/icons/anglo-icon-globe-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-globe-outline.svg) no-repeat center !important;
}
.google-icon {
  mask: url(/assets/icons/anglo-icon-google-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-google-outline.svg) no-repeat center !important;
}
.grid-icon {
  mask: url(/assets/icons/anglo-icon-grid-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-grid-outline.svg) no-repeat center !important;
}
.hard-drive-icon {
  mask: url(/assets/icons/anglo-icon-hard-drive-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-hard-drive-outline.svg) no-repeat center !important;
}
.hash-icon {
  mask: url(/assets/icons/anglo-icon-hash-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-hash-outline.svg) no-repeat center !important;
}
.headphones-icon {
  mask: url(/assets/icons/anglo-icon-headphones-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-headphones-outline.svg) no-repeat center !important;
}
.heart-icon {
  mask: url(/assets/icons/anglo-icon-heart-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-heart-outline.svg) no-repeat center !important;
}
.home-icon {
  mask: url(/assets/icons/anglo-icon-home-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-home-outline.svg) no-repeat center !important;
}
.image-icon {
  mask: url(/assets/icons/anglo-icon-image-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-image-outline.svg) no-repeat center !important;
}
.inbox-icon {
  mask: url(/assets/icons/anglo-icon-inbox-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-inbox-outline.svg) no-repeat center !important;
}
.info-icon {
  mask: url(/assets/icons/anglo-icon-info-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-info-outline.svg) no-repeat center !important;
}
.keypad-icon {
  mask: url(/assets/icons/anglo-icon-keypad-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-keypad-outline.svg) no-repeat center !important;
}
.layers-icon {
  mask: url(/assets/icons/anglo-icon-layers-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-layers-outline.svg) no-repeat center !important;
}
.link-2-icon {
  mask: url(/assets/icons/anglo-icon-link-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-link-2-outline.svg) no-repeat center !important;
}
.linkedin-icon {
  mask: url(/assets/icons/anglo-icon-linkedin-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-linkedin-outline.svg) no-repeat center !important;
}
.link-icon {
  mask: url(/assets/icons/anglo-icon-link-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-link-outline.svg) no-repeat center !important;
}
.list-icon {
  mask: url(/assets/icons/anglo-icon-list-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-list-outline.svg) no-repeat center !important;
}
.loader-icon {
  mask: url(/assets/icons/anglo-icon-loader-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-loader-outline.svg) no-repeat center !important;
}
.lock-icon {
  mask: url(/assets/icons/anglo-icon-lock-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-lock-outline.svg) no-repeat center !important;
}
.log-in-icon {
  mask: url(/assets/icons/anglo-icon-log-in-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-log-in-outline.svg) no-repeat center !important;
}
.log-out-icon {
  mask: url(/assets/icons/anglo-icon-log-out-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-log-out-outline.svg) no-repeat center !important;
}
.map-icon {
  mask: url(/assets/icons/anglo-icon-map-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-map-outline.svg) no-repeat center !important;
}
.maximize-icon {
  mask: url(/assets/icons/anglo-icon-maximize-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-maximize-outline.svg) no-repeat center !important;
}
.menu-2-icon {
  mask: url(/assets/icons/anglo-icon-menu-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-menu-2-outline.svg) no-repeat center !important;
}
.menu-arrow-icon {
  mask: url(/assets/icons/anglo-icon-menu-arrow-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-menu-arrow-outline.svg) no-repeat center !important;
}
.menu-icon {
  mask: url(/assets/icons/anglo-icon-menu-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-menu-outline.svg) no-repeat center !important;
}
.message-circle-icon {
  mask: url(/assets/icons/anglo-icon-message-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-message-circle-outline.svg) no-repeat center !important;
}
.message-square-icon {
  mask: url(/assets/icons/anglo-icon-message-square-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-message-square-outline.svg) no-repeat center !important;
}
.mic-off-icon {
  mask: url(/assets/icons/anglo-icon-mic-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-mic-off-outline.svg) no-repeat center !important;
}
.mic-icon {
  mask: url(/assets/icons/anglo-icon-mic-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-mic-outline.svg) no-repeat center !important;
}
.minimize-icon {
  mask: url(/assets/icons/anglo-icon-minimize-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-minimize-outline.svg) no-repeat center !important;
}
.minus-circle-icon {
  mask: url(/assets/icons/anglo-icon-minus-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-minus-circle-outline.svg) no-repeat center !important;
}
.minus-icon {
  mask: url(/assets/icons/anglo-icon-minus-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-minus-outline.svg) no-repeat center !important;
}
.minus-square-icon {
  mask: url(/assets/icons/anglo-icon-minus-square-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-minus-square-outline.svg) no-repeat center !important;
}
.monitor-icon {
  mask: url(/assets/icons/anglo-icon-monitor-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-monitor-outline.svg) no-repeat center !important;
}
.moon-icon {
  mask: url(/assets/icons/anglo-icon-moon-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-moon-outline.svg) no-repeat center !important;
}
.more-horizontal-icon {
  mask: url(/assets/icons/anglo-icon-more-horizontal-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-more-horizontal-outline.svg) no-repeat center !important;
}
.more-vertical-icon {
  mask: url(/assets/icons/anglo-icon-more-vertical-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-more-vertical-outline.svg) no-repeat center !important;
}
.move-icon {
  mask: url(/assets/icons/anglo-icon-move-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-move-outline.svg) no-repeat center !important;
}
.music-icon {
  mask: url(/assets/icons/anglo-icon-music-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-music-outline.svg) no-repeat center !important;
}
.navigation-2-icon {
  mask: url(/assets/icons/anglo-icon-navigation-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-navigation-2-outline.svg) no-repeat center !important;
}
.navigation-icon {
  mask: url(/assets/icons/anglo-icon-navigation-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-navigation-outline.svg) no-repeat center !important;
}
.npm-icon {
  mask: url(/assets/icons/anglo-icon-npm-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-npm-outline.svg) no-repeat center !important;
}
.options-2-icon {
  mask: url(/assets/icons/anglo-icon-options-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-options-2-outline.svg) no-repeat center !important;
}
.options-icon {
  mask: url(/assets/icons/anglo-icon-options-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-options-outline.svg) no-repeat center !important;
}
.pantone-icon {
  mask: url(/assets/icons/anglo-icon-pantone-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-pantone-outline.svg) no-repeat center !important;
}
.paper-plane-icon {
  mask: url(/assets/icons/anglo-icon-paper-plane-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-paper-plane-outline.svg) no-repeat center !important;
}
.pause-circle-icon {
  mask: url(/assets/icons/anglo-icon-pause-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-pause-circle-outline.svg) no-repeat center !important;
}
.people-icon {
  mask: url(/assets/icons/anglo-icon-people-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-people-outline.svg) no-repeat center !important;
}
.percent-icon {
  mask: url(/assets/icons/anglo-icon-percent-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-percent-outline.svg) no-repeat center !important;
}
.person-add-icon {
  mask: url(/assets/icons/anglo-icon-person-add-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-person-add-outline.svg) no-repeat center !important;
}
.person-done-icon {
  mask: url(/assets/icons/anglo-icon-person-done-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-person-done-outline.svg) no-repeat center !important;
}
.person-icon {
  mask: url(/assets/icons/anglo-icon-person-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-person-outline.svg) no-repeat center !important;
}
.person-remove-icon {
  mask: url(/assets/icons/anglo-icon-person-remove-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-person-remove-outline.svg) no-repeat center !important;
}
.phone-call-icon {
  mask: url(/assets/icons/anglo-icon-phone-call-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-phone-call-outline.svg) no-repeat center !important;
}
.phone-missed-icon {
  mask: url(/assets/icons/anglo-icon-phone-missed-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-phone-missed-outline.svg) no-repeat center !important;
}
.phone-off-icon {
  mask: url(/assets/icons/anglo-icon-phone-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-phone-off-outline.svg) no-repeat center !important;
}
.phone-icon {
  mask: url(/assets/icons/anglo-icon-phone-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-phone-outline.svg) no-repeat center !important;
}
.pie-chart-icon {
  mask: url(/assets/icons/anglo-icon-pie-chart-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-pie-chart-outline.svg) no-repeat center !important;
}
.pin-icon {
  mask: url(/assets/icons/anglo-icon-pin-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-pin-outline.svg) no-repeat center !important;
}
.play-circle-icon {
  mask: url(/assets/icons/anglo-icon-play-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-play-circle-outline.svg) no-repeat center !important;
}
.plus-circle-icon {
  mask: url(/assets/icons/anglo-icon-plus-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-plus-circle-outline.svg) no-repeat center !important;
}
.plus-icon {
  mask: url(/assets/icons/anglo-icon-plus-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-plus-outline.svg) no-repeat center !important;
}
.plus-square-icon {
  mask: url(/assets/icons/anglo-icon-plus-square-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-plus-square-outline.svg) no-repeat center !important;
}
.power-icon {
  mask: url(/assets/icons/anglo-icon-power-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-power-outline.svg) no-repeat center !important;
}
.pricetags-icon {
  mask: url(/assets/icons/anglo-icon-pricetags-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-pricetags-outline.svg) no-repeat center !important;
}
.printer-icon {
  mask: url(/assets/icons/anglo-icon-printer-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-printer-outline.svg) no-repeat center !important;
}
.question-mark-circle-icon {
  mask: url(/assets/icons/anglo-icon-question-mark-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-question-mark-circle-outline.svg) no-repeat center !important;
}
.question-mark-icon {
  mask: url(/assets/icons/anglo-icon-question-mark-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-question-mark-outline.svg) no-repeat center !important;
}
.radio-button-off-icon {
  mask: url(/assets/icons/anglo-icon-radio-button-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-radio-button-off-outline.svg) no-repeat center !important;
}
.radio-button-on-icon {
  mask: url(/assets/icons/anglo-icon-radio-button-on-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-radio-button-on-outline.svg) no-repeat center !important;
}
.radio-icon {
  mask: url(/assets/icons/anglo-icon-radio-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-radio-outline.svg) no-repeat center !important;
}
.recording-icon {
  mask: url(/assets/icons/anglo-icon-recording-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-recording-outline.svg) no-repeat center !important;
}
.refresh-icon {
  mask: url(/assets/icons/anglo-icon-refresh-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-refresh-outline.svg) no-repeat center !important;
}
.repeat-icon {
  mask: url(/assets/icons/anglo-icon-repeat-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-repeat-outline.svg) no-repeat center !important;
}
.rewind-right-icon {
  mask: url(/assets/icons/anglo-icon-rewind-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-rewind-right-outline.svg) no-repeat center !important;
}
.save-icon {
  mask: url(/assets/icons/anglo-icon-save-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-save-outline.svg) no-repeat center !important;
}
.scissor-icon {
  mask: url(/assets/icons/anglo-icon-scissor.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-scissor.svg) no-repeat center !important;
}
.search-icon {
  mask: url(/assets/icons/anglo-icon-search.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-search.svg) no-repeat center !important;
}
.settings-2-icon {
  mask: url(/assets/icons/anglo-icon-settings-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-settings-2-outline.svg) no-repeat center !important;
}
.settings-icon {
  mask: url(/assets/icons/anglo-icon-settings-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-settings-outline.svg) no-repeat center !important;
}
.shake-icon {
  mask: url(/assets/icons/anglo-icon-shake-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shake-outline.svg) no-repeat center !important;
}
.share-icon {
  mask: url(/assets/icons/anglo-icon-share-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-share-outline.svg) no-repeat center !important;
}
.shield-off-icon {
  mask: url(/assets/icons/anglo-icon-shield-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shield-off-outline.svg) no-repeat center !important;
}
.shield-icon {
  mask: url(/assets/icons/anglo-icon-shield-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shield-outline.svg) no-repeat center !important;
}
.shopping-bag-icon {
  mask: url(/assets/icons/anglo-icon-shopping-bag-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shopping-bag-outline.svg) no-repeat center !important;
}
.shopping-cart-icon {
  mask: url(/assets/icons/anglo-icon-shopping-cart-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shopping-cart-outline.svg) no-repeat center !important;
}
.shuffle-2-icon {
  mask: url(/assets/icons/anglo-icon-shuffle-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shuffle-2-outline.svg) no-repeat center !important;
}
.shuffle-icon {
  mask: url(/assets/icons/anglo-icon-shuffle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-shuffle-outline.svg) no-repeat center !important;
}
.skip-back-icon {
  mask: url(/assets/icons/anglo-icon-skip-back-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-skip-back-outline.svg) no-repeat center !important;
}
.skip-forward-icon {
  mask: url(/assets/icons/anglo-icon-skip-forward-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-skip-forward-outline.svg) no-repeat center !important;
}
.slash-icon {
  mask: url(/assets/icons/anglo-icon-slash-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-slash-outline.svg) no-repeat center !important;
}
.smartphone-icon {
  mask: url(/assets/icons/anglo-icon-smartphone-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-smartphone-outline.svg) no-repeat center !important;
}
.smiling-face-icon {
  mask: url(/assets/icons/anglo-icon-smiling-face-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-smiling-face-outline.svg) no-repeat center !important;
}
.speaker-icon {
  mask: url(/assets/icons/anglo-icon-speaker-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-speaker-outline.svg) no-repeat center !important;
}
.square-icon {
  mask: url(/assets/icons/anglo-icon-square-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-square-outline.svg) no-repeat center !important;
}
.star-icon {
  mask: url(/assets/icons/anglo-icon-star-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-star-outline.svg) no-repeat center !important;
}
.stop-circle-icon {
  mask: url(/assets/icons/anglo-icon-stop-circle-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-stop-circle-outline.svg) no-repeat center !important;
}
.sun-icon {
  mask: url(/assets/icons/anglo-icon-sun-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-sun-outline.svg) no-repeat center !important;
}
.swap-icon {
  mask: url(/assets/icons/anglo-icon-swap-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-swap-outline.svg) no-repeat center !important;
}
.sync-icon {
  mask: url(/assets/icons/anglo-icon-sync-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-sync-outline.svg) no-repeat center !important;
}
.text-icon {
  mask: url(/assets/icons/anglo-icon-text-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-text-outline.svg) no-repeat center !important;
}
.thermometer-minus-icon {
  mask: url(/assets/icons/anglo-icon-thermometer-minus-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-thermometer-minus-outline.svg) no-repeat center !important;
}
.thermometer-icon {
  mask: url(/assets/icons/anglo-icon-thermometer-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-thermometer-outline.svg) no-repeat center !important;
}
.thermometer-plus-icon {
  mask: url(/assets/icons/anglo-icon-thermometer-plus-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-thermometer-plus-outline.svg) no-repeat center !important;
}
.thermometer-plus-outline-2x-icon {
  mask: url(/assets/icons/anglo-icon-thermometer-plus-outline@2x.png) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-thermometer-plus-outline@2x.png) no-repeat center !important;
}
.toggle-right-icon {
  mask: url(/assets/icons/anglo-icon-toggle-right-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-toggle-right-outline.svg) no-repeat center !important;
}
.trash-2-icon {
  mask: url(/assets/icons/anglo-icon-trash-2-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-trash-2-outline.svg) no-repeat center !important;
}
.trash-icon {
  mask: url(/assets/icons/anglo-icon-trash-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-trash-outline.svg) no-repeat center !important;
}
.trending-down-icon {
  mask: url(/assets/icons/anglo-icon-trending-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-trending-down-outline.svg) no-repeat center !important;
}
.trending-up-icon {
  mask: url(/assets/icons/anglo-icon-trending-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-trending-up-outline.svg) no-repeat center !important;
}
.tv-icon {
  mask: url(/assets/icons/anglo-icon-tv-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-tv-outline.svg) no-repeat center !important;
}
.twitter-icon {
  mask: url(/assets/icons/anglo-icon-twitter-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-twitter-outline.svg) no-repeat center !important;
}
.umbrella-icon {
  mask: url(/assets/icons/anglo-icon-umbrella-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-umbrella-outline.svg) no-repeat center !important;
}
.undo-icon {
  mask: url(/assets/icons/anglo-icon-undo-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-undo-outline.svg) no-repeat center !important;
}
.unlock-icon {
  mask: url(/assets/icons/anglo-icon-unlock-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-unlock-outline.svg) no-repeat center !important;
}
.upload-icon {
  mask: url(/assets/icons/anglo-icon-upload-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-upload-outline.svg) no-repeat center !important;
}
.video-off-icon {
  mask: url(/assets/icons/anglo-icon-video-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-video-off-outline.svg) no-repeat center !important;
}
.video-icon {
  mask: url(/assets/icons/anglo-icon-video-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-video-outline.svg) no-repeat center !important;
}
.volume-down-icon {
  mask: url(/assets/icons/anglo-icon-volume-down-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-volume-down-outline.svg) no-repeat center !important;
}
.volume-mute-icon {
  mask: url(/assets/icons/anglo-icon-volume-mute-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-volume-mute-outline.svg) no-repeat center !important;
}
.volume-off-icon {
  mask: url(/assets/icons/anglo-icon-volume-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-volume-off-outline.svg) no-repeat center !important;
}
.volume-up-icon {
  mask: url(/assets/icons/anglo-icon-volume-up-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-volume-up-outline.svg) no-repeat center !important;
}
.wifi-off-icon {
  mask: url(/assets/icons/anglo-icon-wifi-off-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-wifi-off-outline.svg) no-repeat center !important;
}
.wifi-icon {
  mask: url(/assets/icons/anglo-icon-wifi-outline.svg) no-repeat center !important;
  -webkit-mask: url(/assets/icons/anglo-icon-wifi-outline.svg) no-repeat center !important;
}
