@use "./colors" as *;

.btn-rounded {
  padding: 8px !important;
  height: 85px !important;
  width: 85px !important;
  align-items: center !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  outline: 0 transparent solid !important;
}

.btn {
  padding: 12px 16px;
  height: 58px;
  align-items: center !important;
  width: 100% !important;
  font-weight: 400;
  outline: 0 transparent solid !important;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  border: 0px;
  &.btn-lg {
    height: 40px;
    font-size: 14px;
  }
  &.btn-sm {
    height: 42px;
    font-size: 14px;
  }
}

.little-btn {
  padding: 8px 16px;
  align-items: center !important;
  width: 100% !important;
  font-weight: 400;
  outline: 0 transparent solid !important;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
}

.btn:focus {
  outline: 0 transparent solid !important;
  box-shadow: none !important;
}

.btn:hover {
  -webkit-animation: headShake 1s;
  animation: headShake 1s;
}
.btn:active {
  outline: 0 transparent solid !important;
  box-shadow: none !important;
}

.btn-default-white-orange {
  padding: 8px;
  height: 28px;
  text-transform: uppercase;
  width: 100%;
  font-weight: bold;
  line-height: 8px;
  font-size: 10px;
  color: rgba(232, 78, 39, 0.4);
  border: 1px solid rgba(232, 78, 39, 0.4);
  background-color: #ffffff;
}

.btn:disabled {
  background-color: #dddddd !important;
  border: none;
  color: #f0f0f0;
}

.btn-default-pink {
  background-color: #e31c79 !important;
  color: #fff !important;
}

.btn-default-yellow {
  background-color: #f9d649 !important;
  color: #6d4976 !important;
}

.btn-default-light-green {
  background-color: #E6F5F4 !important;
  color: #6d4976 !important;
  border: 1px solid #ac89b6 !important;
}

.btn-default-green {
  background-color: #2cc09c !important;
  color: white !important;
}

.btn-default-green:hover {
  color: #2cc09c !important;
  border: solid 1px #2cc09c !important;
  background-color: white !important;
}

.btn-default-gray {
  background-color: #b5b5b5 !important;
  color: white !important;
}

.btn-default-gray:hover {
  color: #fff !important;
  border: solid 1px #b5b5b5 !important;
  background-color: rgb(97, 97, 97) !important;
}

.btn-default-orange {
  background-color: #fc9366 !important;
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px;
}

.btn-default-orange:hover {
  color: #fc9366 !important;
  border: solid 1px #fc9366 !important;
  background-color: #fff !important;
}

.btn-default-light-gray {
  background-color: #d9d9d94d !important;
  color: #000 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px;
  border: 1px #dbdbdb solid;
}

.btn-default-light-gray:hover {
  color: #fff !important;
  border: solid 1px #d9d9d94d !important;
  background-color: rgb(97, 97, 97) !important;
}
.btn-default-green-active {
  background-color: #597945 !important;
  color: white !important;
}
.btn-default-yellow {
  background-color: #f9d649 !important;
  color: #000 !important;
}
.btn-default-yellow:hover {
  background-color: #000 !important;
  color: #f9d649 !important;
  border: 1px solid #f9d649 !important;
}

.btn-default-black {
  background-color: #000 !important;
  color: #f9d649 !important;
}
.btn-default-black:hover {
  background-color: #f9d649 !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

.btn-default-white {
  background-color: #ffffff !important;
  color: #fc9366 !important;
  border: 1px solid #fc9366 !important;
  &:hover {
    background-color: #fc9366!important;
    color: #FFFFFF!important;
  }
}

.btn-default-transparent-white {
  background-color: #ffffff00;
  color: #fff;
  border: 1px solid #fff;
}
.btn-default-transparent-white:hover {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #fff;
}

.cost-sect {
  background: rgba(237, 186, 172, 0.25) !important;
  padding: 2px !important;
  text-align: center !important;
}
