@use "./colors.scss" as *;


.form-group {
  label {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 3px;
    color: #444444
  }
}

.default-field {
  padding: 10px 10px;
  min-height: 44px;
  outline: 2px solid #e2e2e2;
  border: none;
  border-radius: 6px;
  background-color: $default-white !important;
  font-weight: 300;
  display: block;
  width: 100% !important;
  font-size: 14px;
  font-weight: 500;
}

.default-field:focus {
  outline: 2px solid #3fc09c;
  border-radius: 8px;
  display: block;
  box-shadow: none !important;
  background-color: $default-white;
  transition: all 1s;
}

.semi-field {
  height: 58px;
  padding: 16px;
  border: 1px solid #dbdad9;
  background-color: #f2f3f7;
  font-weight: 300;
  border-radius: 8px;
  display: block;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none !important;
}

.semi-field:focus {
  height: 58px;
  padding: 16px;
  border: 1px solid #dddddd !important;
  background-color: #f2f3f7;
  font-weight: 300;
  border-radius: 8px;
  display: block;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none !important;
}

.field-icon-sect {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate(-50%, 0%);
  img {
    width: 18px;
    height: 18px;
    opacity: 0.3;
    margin-top: -2px;
  }
}

.field-icon-search {
  position: absolute;
  left: 4px;
  top: 48.5%;
}

.field-icon-x {
  position: absolute;
  right: 10px;
  top: 48.5%;
}
.form-group > .field-icon {
  bottom: 44px;
  right: 12px;
  position: relative;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.options-sect {
  position: absolute;
  border: 1px solid #dddddd !important;
  background-color: #f2f3f7;
  font-weight: 300;
  border-radius: 8px;
  width: 91.5%;
  padding-top: 8px;
  padding-bottom: 16px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
  max-height: 150px;
  z-index: 99;
  overflow-y: auto !important;
}

.option-row {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.passowd-field {
  -webkit-text-security: disc;
  -moz-text-security: circle;
}

label {
  font-size: 14px;
  color: $default-natural-black;
}

.upper-case {
  text-transform: capitalize;
}

.fieldSearch {
  padding: 24px;
}

.fieldSearch:focus {
  padding: 24px;
}
