@media only screen and (min-width: 801px) {
  .mobile {
    display: none !important;
  }

  .col-md-2-5 {
    flex: 0 0 auto;
    width: 20%;
  }

  .filter-icon-sect {
    position: absolute;
    display: block;
    right: 0;
    top: 50vh;
    height: 5vh;
    padding: 8px 8px;
    background-color: #fff;
    border: #fff solid 1px;
    border-top: 0px;
    border-right: 0px;
  }

  .header {
    background-color: #f2f2f2 !important;
    padding: 16px 30px 16px 60px !important;
    width: 100% !important;
    z-index: 5 !important;
  }

  .f-page-title {
    font-size: 48px;
  }
}
