@import url(./_common_colors.scss);

.f-bold {
    font-weight: bold !important;
}

.f-purple {
    color: #6D4976;
}

.f-pink {
  color: #E31C79
}

.f-green {
    color: #64E318;
}

.f-yellow {
    color: #F3CF31;
}

.f-gray {
    color: #9e9e9e !important;
}

.f-orange {
    color: #E84E27;
}

.f-keepee-green {
    color: #2cc09d
}

.f-salmon {
    color: #F3C7AB;
}

.f-blue {
    color: #47A3C5;
}

.f-white {
    color: white;
}

.f-black {
    color: #000;
}

.f-red {
    color: #b83a14;
}

.f-link {
  cursor: pointer;
  color: #FC8149;
}

.break-line {
    white-space: pre;
}

.f-under-line {
    text-decoration: underline;
}

.f-uppercase {
  text-transform: uppercase;
}

fa-icon {
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer;
}


.cursor-pointer:hover {
  -webkit-animation: headShake 1s !important;
  animation: headShake 1s !important;
}

fa-icon:hover {
  -webkit-animation: headShake 1s !important;
  animation: headShake 1s !important;
}
a:hover {
  -webkit-animation: headShake 1s !important;
  animation: headShake 1s !important;
}

.f-2px {
    font-size: 2px !important;
}

.f-4px {
    font-size: 4px !important;
}

.f-6px {
    font-size: 6px !important;
}

.f-8px {
    font-size: 8px !important;
}

.f-10px {
    font-size: 10px !important;
}

.f-12px {
    font-size: 12px !important;
}

.f-14px {
    font-size: 14px !important;
}

.f-16px {
    font-size: 16px !important;
}

.f-18px {
    font-size: 18px !important;
}

.f-20px {
    font-size: 20px !important;
}

.f-22px {
    font-size: 22px !important;
}

.f-24px {
    font-size: 24px !important;
}

.f-26px {
    font-size: 26px !important;
}

.f-28px {
    font-size: 28px !important;
}

.f-30px {
    font-size: 30px !important;
}

.f-32px {
    font-size: 32px !important;
}

.f-34px {
    font-size: 34px !important;
}

.f-36px {
    font-size: 36px !important;
}

.f-38px {
    font-size: 38px !important;
}

.f-40px {
    font-size: 40px !important;
}
.f-42px {
    font-size: 42px !important;
}
.f-44px {
    font-size: 44px !important;
}
.f-46px {
    font-size: 46px !important;
}
.f-48px {
    font-size: 48px !important;
}

.lh-2px {
    line-height: 2px;
}

.lh-4px {
    line-height: 4px;
}

.lh-6px {
    line-height: 6px;
}

.lh-8px {
    line-height: 8px;
}

.lh-10px {
    line-height: 10px;
}

.lh-12px {
    line-height: 12px !important;
}

.lh-14px {
    line-height: 14px !important;
}

.lh-16px {
    line-height: 16px;
}

.lh-18px {
    line-height: 18px;
}

.lh-20px {
    line-height: 20px;
}

.lh-22px {
    line-height: 22px;
}

.lh-24px {
    line-height: 24px;
}

.lh-26px {
    line-height: 26px;
}

.lh-28px {
    line-height: 28px;
}

.lh-30px {
    line-height: 30px;
}

.lh-32px {
    line-height: 32px;
}

.lh-34px {
    line-height: 34px;
}

.lh-36px {
    line-height: 36px;
}

.lh-40px {
    line-height: 40px;
}
