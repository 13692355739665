$default-yellow: #FFA125;
$default-highlight-yellow: #FFA125;
$default-orange: #FC8149;
$default-black: #201613;
$default-natural-black: #3D3432;
$default-white: #FFFFFF;
$default-green: #2CC09C;
$default-red: #F43D30;
$default-blue: #3B86E9;
$default-natural-grey: #DBDAD9;
